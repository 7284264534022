export const productFormSchema = {
  status: 200,
  result: {
    properties: {
      name: null,
      label: null,
      description: null,
      components: [
        // Article
        {
          id: null,
          key: "2391869b48d04693845fbbe1841ee621",
          type: "panel",
          required: false,
          label: "Article",
          labelKey: "PRODUCT.P000",
          unique: false,
          default: null,
          tooltip: null,
          description: null,
          data: null,
          hidden: false,
          protected: false,
          allowedAccessRoles: ["PRODUCT_METADATA", "PRODUCT_ASSORTMENT"],
          components: [
            {
              id: null,
              key: "C005",
              type: "textfield",
              required: true,
              validate: true,
              label: "Internal name",
              labelKey: "PRODUCT.P001",
              unique: false,
              default: null,
              tooltip: '"name"',
              description: "C005, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              normalized: true,
            },
            {
              id: null,
              key: "C004",
              type: "textfield",
              required: false,
              label: "Product group",
              labelKey: "PRODUCT.P002",
              unique: false,
              default: "",
              tooltip: "",
              description: "C004, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "C003",
              type: "bool",
              required: false,
              validate: false,
              label: "Is Public",
              labelKey: "PRODUCT.P003",
              unique: false,
              default: false,
              tooltip: "",
              description: "C003, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "C002",
              type: "select",
              required: true,
              validate: true,
              watchValidity: ["U005", "U004"],
              label: "Product type",
              labelKey: "PRODUCT.P004",
              unique: false,
              default: "PACK",
              tooltip: "",
              description: "C002, , ",
              data: {
                values: [
                  { value: "PACK", label: "PACK", labelKey: "PRODUCT.P005" },
                  { label: "ITEM", labelKey: "PRODUCT.P006", value: "ITEM" },
                  { label: "SET", labelKey: "PRODUCT.P007", value: "SET" },
                  { label: "UNIT", labelKey: "PRODUCT.P008", value: "UNIT" },
                ],
                hideNoSelectOpt: true,
                json: "",
                url: "",
                resource: "",
                custom: "",
              },
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "U001",
              type: "number",
              dataType: "int",
              required: false,
              label: "Sales units",
              labelKey: "PRODUCT.P009",
              unique: false,
              default: null,
              tooltip: "",

              description: "U001, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanZero",
                },
              ],
            },
            {
              id: null,
              key: "U002",
              type: "textfield",
              required: false,
              label: "Unit title",
              labelKey: "PRODUCT.P010",
              unique: false,
              default: null,
              tooltip: '"weight"',
              description: "U002, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "C010",
              type: "textfield",
              required: false,
              label: "EMCS class",
              labelKey: "PRODUCT.P120",
              normalized: true,
              isVisibleTo: [11, 12],
            },
            {
              id: null,
              key: "C100",
              type: "number",
              required: false,
              label: "Alcohol by volume",
              labelKey: "PRODUCT.P121",
              normalized: true,
              isVisibleTo: [11, 12],
            },
            {
              id: null,
              key: "C970",
              type: "textfield",
              required: false,
              label: "Alcohol registry code",
              labelKey: "PRODUCT.P122",
              normalized: true,
              isVisibleTo: [11, 12],
            },
            {
              id: null,
              key: "U004",
              type: "number",
              validate: true,
              required: false,
              label: "Net volume",
              labelKey: "PRODUCT.P011",
              unique: false,
              default: null,
              tooltip: '"weight"',
              description: "U004, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validators: [
                {
                  callback: "required",
                  requiredIf: "'{C002}' != 'ITEM'",
                },
                {
                  callback: "checkNetVolume",
                },
              ],
            },
            {
              id: null,
              key: "U005",
              type: "select",
              required: false,
              label: "Volume title",
              labelKey: "PRODUCT.P012",
              unique: false,
              default: "g",
              tooltip: "",
              description: "U005, , ",
              data: {
                values: [],
                json: "",
                url: "",
                resource: "",
                custom: "",
                hideNoSelectOpt: true,
              },
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                { callback: "required", requiredIf: "'{C002}' != 'ITEM'" },
              ],
            },
            {
              id: null,
              key: "U003",
              type: "number",
              required: false,
              label: "Gross volume",
              labelKey: "PRODUCT.P013",
              unique: false,
              default: null,
              tooltip: '"weight"',
              description: "U003, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanZero",
                },
              ],
            },
            {
              id: null,
              key: "U006",
              type: "number",
              required: false,
              validate: true,
              label: "Max deviation",
              labelKey: "PRODUCT.P014",
              unique: false,
              default: null,
              tooltip: "",
              description: "U006, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validators: [
                { callback: "maxDeviation", requiredIf: "{C007} == true" },
                {
                  callback: "greaterThanEqualToZero",
                },
              ],
            },
            {
              id: null,
              key: "L000",
              type: "textfield",
              required: false,
              label: "Producer",
              labelKey: "PRODUCT.P015",
              unique: false,
              default: null,
              tooltip: "",
              description: "L000, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "L001",
              type: "textfield",
              required: false,
              label: "Product origin",
              labelKey: "PRODUCT.P016",
              unique: false,
              default: null,
              tooltip: "",
              description: "L001, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "L900",
              type: "textarea",
              required: false,
              label: "Internal comment",
              labelKey: "PRODUCT.P017",
              unique: false,
              default: null,
              tooltip: "",
              description: "L900, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "A007",
              type: "select",
              dataType: "int",
              label: "Product lifecycle",
              labelKey: "PRODUCT.P018",
              unique: false,
              default: 1,
              missingPropValue: null,
              tooltip: "",
              description: "A007, , ",
              data: {
                values: [
                  { label: "Incoming", labelKey: "PRODUCT.P019", value: 1 },
                  { label: "On Sale", labelKey: "PRODUCT.P020", value: 2 },
                  { label: "Paused", labelKey: "PRODUCT.P021", value: 3 },
                  { label: "Removed", labelKey: "PRODUCT.P022", value: 4 },
                ],
                hideNoSelectOpt: false,
                defaultOptValue: null,
                json: "",
                url: "",
                resource: "",
                custom: "",
              },
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "C007",
              type: "bool",
              required: false,
              label: "Has variable weight",
              labelKey: "PRODUCT.P023",
              unique: false,
              default: false,
              tooltip: "",
              description: "C007, , ",
              data: null,
              hidden: true,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "S011",
              type: "dropdown",
              dataType: "int",
              label: "Vendor",
              labelKey: "PRODUCT.P024",
              unique: false,
              default: null,
              tooltip: '"vendor"',
              data: {
                values: [],
                json: "",
                url: "",
                resource: "",
                custom: "",
              },
              hidden: false,
              protected: false,
              components: null,
            },
          ],
        },
        // Display
        {
          id: null,
          key: "2391869b48d04693845fbbe1841ee622ff",
          type: "panel",
          ref: "displaygroup",
          required: false,
          label: "Display",
          labelKey: "PRODUCT.P025",
          unique: false,
          default: null,
          tooltip: null,
          description: null,
          data: null,
          hidden: false,
          protected: false,
          allowedAccessRoles: ["CHANNEL_ASSORTMENT", "PRODUCT_ASSORTMENT"],
          components: [
            {
              id: null,
              type: "form-array",
              key: "D000",
              components: [
                {
                  id: null,
                  key: "D001",
                  type: "dropdown",
                  required: false,
                  label: "Display group",
                  labelKey: "PRODUCT.P026",
                  unique: false,
                  default: null,
                  tooltip: '"category_links" > "category_id"',
                  description: "D001, , ",
                  data: {
                    values: [],
                    json: "",
                    url: "",
                    resource: "",
                    custom: "",
                  },
                  hidden: false,
                  protected: false,
                  components: null,
                },
                {
                  id: null,
                  key: "D002",
                  type: "bool",
                  required: false,
                  label: "In assortment",
                  labelKey: "PRODUCT.P027",
                  unique: false,
                  default: false,
                  tooltip: '"visibilities" > "is_visible"',
                  description: "D002, , ",
                  data: null,
                  hidden: false,
                  protected: false,
                  components: null,
                },
              ],
              multiValue: true,
            },
          ],
        },
        // Labels
        {
          id: null,
          key: "02bd490e31824025b04a88eda88afa74",
          type: "panel",
          required: false,
          label: "Labels",
          labelKey: "PRODUCT.P028",
          unique: false,
          default: null,
          tooltip: null,
          description: null,
          data: null,
          hidden: false,
          protected: false,
          allowedAccessRoles: ["PRODUCT_METADATA", "PRODUCT_ASSORTMENT"],
          components: [
            {
              id: null,
              key: "L005",
              type: "textfield",
              required: false,
              label: "Primary label",
              labelKey: "PRODUCT.P029",
              unique: false,
              default: null,
              tooltip: '"name"',
              description: "L005, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [{ callback: "required" }],
            },
            {
              id: null,
              key: "L006",
              type: "textfield",
              required: false,
              label: "Secondary label",
              labelKey: "PRODUCT.P030",
              unique: false,
              default: null,
              tooltip: "",
              description: "L006, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "L008",
              type: "textarea",
              required: false,
              label: "Lead text",
              labelKey: "PRODUCT.P031",
              unique: false,
              default: null,
              tooltip: '"seo_meta_description"',
              description: "L008, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "L009",
              type: "textarea",
              required: false,
              label: "Body text",
              labelKey: "PRODUCT.P032",
              unique: false,
              default: null,
              tooltip: '"description" + "keywords"',
              description: "L009, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "L007",
              type: "textarea",
              required: false,
              label: "Ingredient list",
              labelKey: "PRODUCT.P033",
              unique: false,
              default: null,
              tooltip: '"information"',
              description: "L007, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "L012",
              type: "tags",
              required: false,
              label: "Search words",
              labelKey: "PRODUCT.P034",
              unique: false,
              placeholder: "Enter a new search word",
              placeholderKey: "PRODUCT.P110",
              tooltip: "Search word",
              description: "L012, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "D005",
              type: "tags",
              required: false,
              label: "Tags",
              labelKey: "PRODUCT.P035",
              unique: false,
              default: null,
              placeholder: "Enter a new tag",
              placeholderKey: "PRODUCT.P111",
              tooltip: "Tag",
              description: "D005, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
          ],
        },
        // Pictures
        {
          id: null,
          key: "02bd490e31824025b04a88eda88afa74_pictures",
          ref: "pictures",
          type: "panel",
          required: false,
          label: "Pictures",
          labelKey: "PRODUCT.P036",
          unique: false,
          default: null,
          tooltip: null,
          description: null,
          data: null,
          hidden: false,
          protected: false,
          allowedAccessRoles: ["PRODUCT_METADATA", "PRODUCT_ASSORTMENT"],
          components: [
            {
              id: null,
              key: "images",
              type: "file",
              required: false,
              label: "Product pictures",
              labelKey: "PRODUCT.P037",
              unique: false,
              default: null,
              tooltip: '"image_links" > "filename"',
              description: "fileUpload, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
          ],
        },
        // Nutrition
        {
          id: null,
          key: "e15349f13a0c47b087c2d48e11a4e1xd",
          type: "panel",
          required: false,
          label: "Nutrition",
          labelKey: "PRODUCT.P038",
          unique: false,
          default: null,
          tooltip: null,
          description: null,
          data: null,
          hidden: false,
          protected: false,
          allowedAccessRoles: ["PRODUCT_METADATA", "PRODUCT_ASSORTMENT"],
          components: [
            {
              id: null,
              key: "N002",
              type: "textfield",
              required: false,
              label: "Portion size",
              labelKey: "PRODUCT.P039",
              unique: false,
              default: "100",
              tooltip: "",
              description: "N002, , ",
              data: null,
              hidden: false,
              protected: true,
              components: null,
            },
            {
              id: null,
              key: "N003",
              type: "textfield",
              required: false,
              label: "Portion unit",
              labelKey: "PRODUCT.P040",
              unique: false,
              default: "g",
              tooltip: "",
              description: "N003, , ",
              data: null,
              hidden: false,
              protected: true,
              components: null,
            },
            {
              id: null,
              key: "N001",
              type: "number",
              dataType: "int",
              required: false,
              label: "Energetic value (kcal)",
              labelKey: "PRODUCT.P041",
              unique: false,
              default: null,
              tooltip: "",
              description: "N001, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanEqualToZero",
                },
              ],
            },
            {
              id: null,
              key: "N004",
              type: "number",
              required: false,
              label: "Fat (g)",
              labelKey: "PRODUCT.P042",
              unique: false,
              default: null,
              tooltip: "",
              description: "N004, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanEqualToZero",
                },
              ],
            },
            {
              id: null,
              key: "N007",
              type: "number",
              required: false,
              label: "Saturated fats (g)",
              labelKey: "PRODUCT.P043",
              unique: false,
              default: null,
              tooltip: "",
              description: "N007, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanEqualToZero",
                },
              ],
            },
            {
              id: null,
              key: "N006",
              type: "number",
              required: false,
              label: "Protein (g)",
              labelKey: "PRODUCT.P044",
              unique: false,
              default: null,
              tooltip: "",
              description: "N006, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanEqualToZero",
                },
              ],
            },
            {
              id: null,
              key: "N005",
              type: "number",
              required: false,
              label: "Carbohydrates (g)",
              labelKey: "PRODUCT.P045",
              unique: false,
              default: null,
              tooltip: "",
              description: "N005, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanEqualToZero",
                },
              ],
            },
            {
              id: null,
              key: "N008",
              type: "number",
              required: false,
              label: "Sugar (g)",
              labelKey: "PRODUCT.P046",
              unique: false,
              default: null,
              tooltip: "",
              description: "N008, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanEqualToZero",
                },
              ],
            },
            {
              id: null,
              key: "N009",
              type: "number",
              required: false,
              label: "Lactose (g)",
              labelKey: "PRODUCT.P047",
              unique: false,
              default: null,
              tooltip: "",
              description: "N009, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanEqualToZero",
                },
              ],
            },
            {
              id: null,
              key: "N010",
              type: "number",
              required: false,
              label: "Fibers (g)",
              labelKey: "PRODUCT.P048",
              unique: false,
              default: null,
              tooltip: "",
              description: "N010, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanEqualToZero",
                },
              ],
            },
            {
              id: null,
              key: "N011",
              type: "number",
              required: false,
              label: "Salt (g)",
              labelKey: "PRODUCT.P049",
              unique: false,
              default: null,
              tooltip: "",
              description: "N011, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanEqualToZero",
                },
              ],
            },
            {
              id: null,
              key: "N012",
              type: "number",
              required: false,
              label: "Calcium (g)",
              labelKey: "PRODUCT.P050",
              unique: false,
              default: null,
              tooltip: "",
              description: "N012, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanEqualToZero",
                },
              ],
            },
          ],
        },
        // Attributes
        {
          id: null,
          key: "e15349f13a0c47b087c2d48e11a4e1ed_attributes",
          type: "panel",
          required: false,
          label: "Attributes",
          labelKey: "PRODUCT.P051",
          unique: false,
          default: null,
          tooltip: null,
          description: null,
          data: null,
          hidden: false,
          protected: false,
          allowedAccessRoles: ["PRODUCT_METADATA", "PRODUCT_ASSORTMENT"],
          components: [
            {
              id: null,
              key: "A011",
              type: "bool",
              required: false,
              label: "Frozen",
              labelKey: "PRODUCT.P052",
              unique: false,
              default: false,
              tooltip: "",
              description: "A011, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "A004",
              type: "bool",
              required: false,
              label: "Organic",
              labelKey: "PRODUCT.P053",
              unique: false,
              default: false,
              tooltip: "",
              description: "A004, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "A014",
              type: "bool",
              required: false,
              label: "Local",
              labelKey: "PRODUCT.P054",
              unique: false,
              default: false,
              tooltip: "",
              description: "A014, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "A001",
              type: "bool",
              required: false,
              label: "Gluten free",
              labelKey: "PRODUCT.P055",
              unique: false,
              default: false,
              tooltip: "",
              description: "A001, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "A002",
              type: "bool",
              required: false,
              label: "Lactose free",
              labelKey: "PRODUCT.P056",
              unique: false,
              default: false,
              tooltip: "",
              description: "A002, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "A003",
              type: "bool",
              required: false,
              label: "Vegan",
              labelKey: "PRODUCT.P057",
              unique: false,
              default: false,
              tooltip: "",
              description: "A003, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "A012",
              type: "bool",
              required: false,
              label: "Oversize",
              labelKey: "PRODUCT.P058",
              unique: false,
              default: false,
              tooltip: "",
              description: "A012, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "A013",
              type: "bool",
              required: false,
              label: "Fragile",
              labelKey: "PRODUCT.P059",
              unique: false,
              default: false,
              tooltip: "",
              description: "A013, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              key: "A015",
              type: "bool",
              required: false,
              label: "Regulated handover",
              labelKey: "PRODUCT.P118",
              default: false,
              isVisibleTo: [11, 12],
            },
          ],
        },
        // Storage and handling
        {
          id: null,
          key: "e15349f13a0c47b087c2d48e11a4e1ed",
          type: "panel",
          required: false,
          label: "Storage and handling",
          labelKey: "PRODUCT.P060",
          unique: false,
          default: null,
          tooltip: null,
          description: null,
          data: null,
          hidden: false,
          protected: false,
          allowedAccessRoles: ["PRODUCT_METADATA", "PRODUCT_ASSORTMENT"],
          components: [
            {
              id: null,
              key: "A006",
              type: "select",
              required: false,
              label: "Storage",
              labelKey: "PRODUCT.P061",
              unique: false,
              default: null,
              tooltip: "",
              description: "A006, , ",
              data: {
                values: [
                  {
                    value: "Freeze",
                    label: "Freeze",
                    labelKey: "PRODUCT.P062",
                    extra: { U015: -25, U016: -18 },
                  },
                  {
                    value: "Cold2",
                    label: "Cold2",
                    labelKey: "PRODUCT.P063",
                    extra: { U015: 1, U016: 3 },
                  },
                  {
                    value: "Cold6",
                    label: "Cold6",
                    labelKey: "PRODUCT.P064",
                    extra: { U015: 3, U016: 6 },
                  },
                  {
                    value: "Cold8",
                    label: "Cold8",
                    labelKey: "PRODUCT.P065",
                    extra: { U015: 7, U016: 9 },
                  },
                  {
                    value: "Warm",
                    label: "Warm",
                    labelKey: "PRODUCT.P066",
                    extra: { U015: 10, U016: 25 },
                  },
                  {
                    value: "Neutral",
                    label: "Neutral",
                    labelKey: "PRODUCT.P067",
                    extra: { U015: 6, U016: 25 },
                  },
                ],
                json: "",
                url: "",
                resource: "",
                custom: "",
              },
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "U015",
              type: "number",
              dataType: "int",
              required: false,
              label: "Min. temperature (℃)",
              labelKey: "PRODUCT.P068",
              unique: false,
              default: null,
              tooltip: "",
              description: "U015, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "tempRange",
                },
              ],
            },
            {
              id: null,
              key: "U016",
              type: "number",
              dataType: "int",
              required: false,
              label: "Max. temperature (℃)",
              labelKey: "PRODUCT.P069",
              unique: false,
              default: null,
              tooltip: "",
              description: "U016, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "tempRange",
                },
              ],
            },
            {
              id: null,
              key: "A008",
              type: "bool",
              required: false,
              label: "Date tracking",
              labelKey: "PRODUCT.P070",
              unique: false,
              default: false,
              tooltip: "",
              description: "A008, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              watchValidity: ["A010"],
            },
            {
              id: null,
              key: "A009",
              type: "number",
              dataType: "int",
              required: false,
              label: "Shelf life (days)",
              labelKey: "PRODUCT.P071",
              unique: false,
              default: null,
              tooltip: "",
              description: "A009, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "A010",
              type: "number",
              dataType: "int",
              required: false,
              label: "Freshness promise (days)",
              labelKey: "PRODUCT.P072",
              unique: false,
              default: null,
              tooltip: "",
              description: "A010, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "required",
                  requiredIf: "{A008} == true",
                },
                {
                  callback: "freshnessPromise",
                  requiredIf: "{A008} == true",
                },
              ],
            },
          ],
        },
        // Price
        {
          id: null,
          key: "603fe43abd4c45fc945036cd31524a29",
          type: "panel",
          required: false,
          label: "Price",
          labelKey: "PRODUCT.P073",
          unique: false,
          default: null,
          tooltip: null,
          description: null,
          data: null,
          hidden: false,
          protected: false,
          allowedAccessRoles: ["PRODUCT_METADATA", "PRODUCT_ASSORTMENT"],
          components: [
            {
              id: null,
              key: "P000",
              type: "number",
              displayFormat: "price",
              required: true,
              label: "Purchase price (€)",
              labelKey: "PRODUCT.P074",
              unique: false,
              default: null,
              tooltip: '"purchase_price"',
              description: "P000, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanEqualToZero",
                },
              ],
            },
            {
              id: null,
              key: "P001",
              type: "number",
              required: false,
              label: "Target margin (%)",
              labelKey: "PRODUCT.P075",
              unique: false,
              default: null,
              tooltip: '"price"',
              description: "P001, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "P002",
              type: "number",
              displayFormat: "price",
              required: false,
              label: "Suggested sales price (€)",
              labelKey: "PRODUCT.P076",
              unique: false,
              default: null,
              tooltip: '"tax"',
              description: "P002, , ",
              data: null,
              hidden: false,
              protected: true,
              components: null,
              watchValidity: ["P003"],
            },
            {
              id: null,
              key: "P003",
              type: "number",
              displayFormat: "price",
              required: false,
              label: "Sales price (€)",
              labelKey: "PRODUCT.P077",
              unique: false,
              default: null,
              tooltip: '"price"',
              description: "P003, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "required",
                  requiredIf: "'{P002}' ? false : true",
                },
                { callback: "greaterThanEqualToZero" },
              ],
            },
            {
              id: null,
              key: "P006",
              type: "textfield",
              required: false,
              label: "Reference unit",
              labelKey: "PRODUCT.P083",
              unique: false,
              default: "kg",
              tooltip: '"tax"',
              description: "P006, , ",
              data: null,
              hidden: false,
              protected: true,
              components: null,
            },
            {
              id: null,
              key: "U007",
              type: "number",
              required: false,
              label: "Volume conversion",
              labelKey: "PRODUCT.P084",
              unique: false,
              default: 1000,
              tooltip: "U007",
              description: "",
              data: null,
              hidden: false,
              protected: true,
              components: null,
            },
          ],
        },
        {
          key: "603fe43abd4c45fc945036cd31524c11",
          type: "panel",
          required: false,
          labelKey: "PRODUCT.P123",
          allowedAccessRoles: ["PRODUCT_METADATA", "PRODUCT_ASSORTMENT"],
          components: [
            {
              key: "P005",
              type: "select",
              dataType: "int",
              required: false,
              label: "Sales tax",
              labelKey: "PRODUCT.P078",
              default: 14,
              data: {
                values: [
                  {
                    value: 25.5,
                    label: "General (25.5%)",
                    labelKey: "PRODUCT.P127",
                  },
                  { value: 14, label: "Food (14%)", labelKey: "PRODUCT.P080" },
                  { value: 10, label: "Books (10%)", labelKey: "PRODUCT.P081" },
                  {
                    value: 0,
                    label: "Tax free (0%)",
                    labelKey: "PRODUCT.P082",
                  },
                ],
                hideNoSelectOpt: true,
                json: "",
                url: "",
                resource: "",
                custom: "",
              },
              validate: true,
              validators: [{ callback: "required" }],
            },
            {
              key: "A520",
              type: "bool",
              required: false,
              labelKey: "PRODUCT.P124",
              default: false,
              isVisibleTo: [12],
            },
            {
              key: "A510",
              type: "bool",
              required: false,
              labelKey: "PRODUCT.P125",
              default: false,
              isVisibleTo: [12],
            },
            {
              key: "P102",
              type: "number",
              required: false,
              labelKey: "PRODUCT.P128",
              default: null,
              isVisibleTo: [12],
              hidden: true,
            },
          ],
        },
        // Marketing
        {
          id: null,
          key: "5997a84dccfb4ef68a0224f616a68c29",
          type: "panel",
          required: false,
          label: "Marketing",
          labelKey: "PRODUCT.P085",
          unique: false,
          default: null,
          tooltip: null,
          description: null,
          data: null,
          hidden: false,
          protected: false,
          allowedAccessRoles: ["PRODUCT_METADATA", "PRODUCT_ASSORTMENT"],
          components: [
            {
              id: null,
              key: "M001",
              type: "textfield",
              required: false,
              label: "Marketing message",
              labelKey: "PRODUCT.P086",
              unique: false,
              default: null,
              tooltip: "",
              description: "M001, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "M002",
              type: "number",
              displayFormat: "price",
              required: false,
              label: "Campaign price (€)",
              labelKey: "PRODUCT.P087",
              unique: false,
              default: null,
              tooltip: "",
              description: "M002, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanEqualToZero",
                },
              ],
            },
            {
              id: null,
              key: "M004",
              type: "date",
              required: false,
              label: "New product",
              labelKey: "PRODUCT.P088",
              unique: false,
              default: null,
              tooltip: "",
              description: "M004, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "M003",
              type: "bool",
              required: false,
              label: "Highlighted product",
              labelKey: "PRODUCT.P089",
              unique: false,
              default: false,
              tooltip: '"featured"',
              description: "M003, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
          ],
        },
        // Delivery
        {
          id: null,
          key: "69308ca5e2154b11860f32ac38d03c67",
          type: "panel",
          required: false,
          label: "Delivery",
          labelKey: "PRODUCT.P090",
          unique: false,
          default: null,
          tooltip: null,
          description: null,
          data: null,
          hidden: false,
          protected: false,
          allowedAccessRoles: ["PRODUCT_METADATA", "PRODUCT_ASSORTMENT"],
          components: [
            {
              id: null,
              key: "S004",
              type: "number",
              dataType: "intText",
              allowNegative: false,
              required: false,
              label: "Delivery GTIN",
              labelKey: "PRODUCT.P091",
              unique: false,
              default: null,
              tooltip: '"product_code"',
              description: "S004, , ",
              data: null,
              hidden: true,
              protected: false,
              components: null,
              validate: true,
              validators: [],
              watchValidity: ["S000"],
            },
            {
              id: null,
              key: "S001",
              type: "number",
              dataType: "intText",
              allowNegative: false,
              required: false,
              label: "Delivery GTIN",
              labelKey: "PRODUCT.P091",
              unique: false,
              default: null,
              tooltip: '"product_code"',
              validate: true,
              validators: [
                { callback: "required", requiredIf: "{C003} && !'{S000}'" },
                { callback: "verifyGtinCode", isHiddenFrom: [12] },
              ],
              watchValidity: ["S000"],
            },
            {
              key: "S000",
              labelKey: "PRODUCT.P119",
              type: "textfield",
              validate: true,
              validators: [
                { callback: "required", requiredIf: "{C003} && !'{S004}'" },
              ],
              watchValidity: ["S004"],
            },
            {
              id: null,
              key: "S002",
              type: "dropdown",
              required: true,
              label: "Supplier",
              labelKey: "PRODUCT.P092",
              placeholder: "Select Supplier",
              placeholderKey: "PRODUCT.P112",
              unique: false,
              default: null,
              tooltip: '"product_code"',
              description: "S002, , ",
              data: {
                values: [],
                json: "",
                url: "",
                resource: "",
                custom: "",
              },
              hidden: false,
              protected: false,
              components: null,
              validate: true,
            },
            {
              id: null,
              key: "U008",
              type: "number",
              dataType: "int",
              required: true,
              label: "Delivery width (mm)",
              labelKey: "PRODUCT.P093",
              unique: false,
              default: null,
              tooltip: "",
              description: "U008, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanZero",
                },
              ],
            },
            {
              id: null,
              key: "U009",
              type: "number",
              dataType: "int",
              required: true,
              label: "Delivery height (mm)",
              labelKey: "PRODUCT.P094",
              unique: false,
              default: null,
              tooltip: "",
              description: "U009, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanZero",
                },
              ],
            },
            {
              id: null,
              key: "U010",
              type: "number",
              dataType: "int",
              required: true,
              label: "Delivery depth (mm)",
              labelKey: "PRODUCT.P095",
              unique: false,
              default: null,
              tooltip: "",
              description: "U010, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanZero",
                },
              ],
            },
            {
              id: null,
              key: "U011",
              type: "number",
              required: true,
              label: "Delivery weight (kg)",
              labelKey: "PRODUCT.P096",
              unique: false,
              default: null,
              tooltip: '"product_code"',
              description: "U011, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanZero",
                },
              ],
            },
            {
              id: null,
              key: "U017",
              type: "number",
              dataType: "int",
              required: false,
              label: "Minimum order",
              labelKey: "PRODUCT.P097",
              unique: false,
              default: null,
              tooltip: '"order_limit_min"',
              description: "U017, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanZero",
                },
              ],
            },
            {
              id: null,
              key: "U018",
              type: "number",
              dataType: "int",
              required: false,
              label: "Maximum order",
              labelKey: "PRODUCT.P098",
              unique: false,
              default: null,
              tooltip: '"product_code"',
              description: "U018, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanZero",
                },
              ],
            },
            {
              id: null,
              key: "S007",
              type: "number",
              dataType: "int",
              required: false,
              label: "Warehouse stock",
              labelKey: "PRODUCT.P099",
              unique: false,
              default: null,
              tooltip: "",
              description: "S007, , ",
              data: null,
              hidden: true,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "U020",
              type: "number",
              dataType: "int",
              label: "Order multiplier",
              labelKey: "PRODUCT.P129",
              default: 1,
            },
          ],
        },
        // Supply
        {
          id: null,
          key: "0e6e23b8ecc54afcad14fa173f09de2c",
          type: "panel",
          required: false,
          label: "Supply",
          labelKey: "PRODUCT.P100",
          unique: false,
          default: null,
          tooltip: null,
          description: null,
          data: null,
          hidden: false,
          protected: false,
          allowedAccessRoles: ["PRODUCT_METADATA", "PRODUCT_ASSORTMENT"],
          components: [
            {
              id: null,
              key: "S005",
              type: "number",
              dataType: "intText",
              allowNegative: false,
              required: false,
              label: "Supply GTIN",
              labelKey: "PRODUCT.P101",
              unique: false,
              default: null,
              tooltip: "",
              description: "S005, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              watchValidity: ["S006", "U012", "U013", "U014"],
            },
            {
              id: null,
              key: "S006",
              type: "number",
              dataType: "int",
              label: "Supply units",
              labelKey: "PRODUCT.P102",
              unique: false,
              default: null,
              tooltip: "",
              description: "S006, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              required: false,
              validate: true,
              validators: [
                { callback: "required", requiredIf: "'{S005}'" },
                {
                  callback: "greaterThanZero",
                },
              ],
            },
            {
              id: null,
              key: "S900",
              type: "textfield",
              required: false,
              label: "Reference key",
              labelKey: "PRODUCT.P103",
              unique: false,
              default: "",
              tooltip: "",
              description: "S900, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },

            {
              id: null,
              key: "S009",
              type: "bool",
              required: false,
              label: "Stock is limited",
              labelKey: "PRODUCT.P104",
              unique: false,
              default: false,
              tooltip: "",
              description: "S009, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
            },
            {
              id: null,
              key: "S901",
              type: "number",
              dataType: "int",
              required: false,
              label: "Reorder alert level",
              labelKey: "PRODUCT.P105",
              unique: false,
              default: null,
              tooltip: "",
              description: "S901, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanEqualToZero",
                },
              ],
            },
            {
              id: null,
              key: "S902",
              type: "number",
              dataType: "int",
              required: false,
              label: "Reorder quantity",
              labelKey: "PRODUCT.P106",
              unique: false,
              default: null,
              tooltip: "",
              description: "S902, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              validate: true,
              validators: [
                {
                  callback: "greaterThanEqualToZero",
                },
              ],
            },

            {
              id: null,
              key: "U012",
              type: "number",
              dataType: "int",
              label: "Supply width (mm)",
              labelKey: "PRODUCT.P107",
              unique: false,
              default: null,
              tooltip: "",
              description: "U012, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              required: false,
              validate: true,
              validators: [
                { callback: "required", requiredIf: "'{S005}'" },
                {
                  callback: "greaterThanZero",
                },
              ],
            },
            {
              id: null,
              key: "U013",
              type: "number",
              dataType: "int",
              label: "Supply height (mm)",
              labelKey: "PRODUCT.P108",
              unique: false,
              default: null,
              tooltip: "",
              description: "U013, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              required: false,
              validate: true,
              validators: [
                { callback: "required", requiredIf: "'{S005}'" },
                {
                  callback: "greaterThanZero",
                },
              ],
            },
            {
              id: null,
              key: "U014",
              type: "number",
              dataType: "int",
              label: "Supply depth (mm)",
              labelKey: "PRODUCT.P109",
              unique: false,
              default: null,
              tooltip: "",
              description: "U014, , ",
              data: null,
              hidden: false,
              protected: false,
              components: null,
              required: false,
              validate: true,
              validators: [
                { callback: "required", requiredIf: "'{S005}'" },
                {
                  callback: "greaterThanZero",
                },
              ],
            },
            {
              labelKey: "PRODUCT.P126",
              key: "S201",
              type: "textfield",
              dataType: "text",
              default: null,
              normalized: true,
              isVisibleTo: [12],
            },
          ],
        },
      ],
    },
  },
};

import { ChannelType } from "./channel.config";

export const CHANNEL_PRODUCT_CONFIG = {
  [ChannelType.JALOVIINI]: [
    { key: "C010" },
    { key: "C100" },
    { key: "C970" },
    { key: "A015" },
    { key: "A520" },
    { key: "A510" },
    { key: "P102" },
    { key: "S201" },
  ],
  [ChannelType.OLUTMAAILMA]: [
    { key: "C010" },
    { key: "C100" },
    { key: "C970" },
    { key: "A015" },
  ],
};

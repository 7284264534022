<ng-select
  [items]="defaultOptions || displayGroups"
  class="dg-selectbox"
  [class.dg-selectbox--has-sublevels]="hasHirerachy"
  [ngClass]="{ 'dg-selectbox--sm': size == 'sm' }"
  name="value"
  bindLabel="name"
  [placeholder]="
    placeholder ? ('PRODUCT.P026' | localeTrans) : defaultPlaceholder
  "
  bindValue="D001"
  groupBy="children"
  [compareWith]="compareDisplayGroupValue"
  [disabled]="disabled || readonly"
  [appendTo]="appendTo"
  [(ngModel)]="value"
  style="min-width: 200px"
  (ngModelChange)="updateChanges()"
  (change)="handleChange()"
  [searchFn]="onSearch"
>
  <ng-template ng-label-tmp let-item="item">
    <div class="dg-title">
      <span class="code" *ngIf="item.D001">{{ item.D001 }}</span>
      {{ item.lang | localeTrans : { resource: true } }}
    </div>
    <span
      class="badge badge-danger dropdown-badge"
      *ngIf="item && item.active == displayGroupStatus.HIDE"
      >Hidden
    </span>
    <span
      class="badge badge-warning dropdown-badge"
      *ngIf="item && item.active == displayGroupStatus.ARCHIVE"
      >Archive
    </span>
  </ng-template>
  <ng-template
    ng-optgroup-tmp
    let-item="item"
    let-item$="item$"
    let-index="index"
  >
    <div class="dg-title">
      <span class="code" *ngIf="item.D001">{{ item.D001 }}</span>
      {{
        (item ? item.lang : "") | localeTrans : { resource: true } | uppercase
      }}
    </div>
    <span
      class="badge badge-danger dropdown-badge"
      *ngIf="item && item.active == displayGroupStatus.HIDE"
      >Hidden
    </span>
    <span
      class="badge badge-warning dropdown-badge"
      *ngIf="item && item.active == displayGroupStatus.ARCHIVE"
      >Archive
    </span>
  </ng-template>
  <ng-template
    ng-option-tmp
    let-item="item"
    let-index="index"
    let-search="searchTerm"
  >
    <div class="dg-title">
      <span class="code" *ngIf="item.D001">{{ item.D001 }}</span>
      {{ item.lang | localeTrans : { resource: true } }}
      <i *ngIf="item.selected" class="fa fa-check-circle default-checked"></i>
    </div>
    <span
      class="badge badge-danger dropdown-badge"
      *ngIf="item.active == displayGroupStatus.HIDE"
      >Hidden
    </span>
    <span
      class="badge badge-warning dropdown-badge"
      *ngIf="item.active == displayGroupStatus.ARCHIVE"
      >Archive
    </span>
  </ng-template>
</ng-select>
